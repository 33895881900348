// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
//import './plugins/lazyload';
import './plugins/modernizr.min';
import './plugins/notification';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/* global ajax_object */
/* global L */

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  $('.gallery .gallery-icon').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Close menu on click elsewhere
   */
  $(document).on('click touchstart', function (e) {
    if (!$(e.target).closest('.header').length) {
      $('#primary-menu').collapse('hide');
    }
  });

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        // TODO: replace with bootstrap toggle
        // $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  $('a.js-product-tab').on('shown.bs.tab', function () {
    let $this = $(this);
    $this.closest('.dropdown').find('.dropdown-toggle').text($this.text());
  });

  if ($('.js-opensource-map').length) {
    openMapInit();
  }

  // Search Resellers
  $('#resellers-search').submit(function () {
    searchResellers($(this));
    return false;
  });

  if ($('#resellers-map').length && $('#resellers-search').length) {
    let resellersPlaceholder =
      $('#resellers-search').attr('data-search') ?? 'Postcode or city';
    let resellersMap = new L.Map('resellers-map', {
      zoom: 9,
      center: new L.latLng([52.1, 4.5]),
    });
    let currLang = $('html').attr('lang');

    resellersMap.addLayer(
      new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')
    ); //base layer

    let controlSearch = new L.Control.Search({
      container: 'resellers-search',
      url:
        'https://nominatim.openstreetmap.org/search?format=json&accept-language=' +
        currLang +
        '&q={s}',
      jsonpParam: 'json_callback',
      propertyName: 'display_name',
      propertyLoc: ['lat', 'lon'],
      marker: L.circleMarker([0, 0], { radius: 30 }),
      autoType: true,
      collapsed: false,
      minLength: 2,
      tooltipLimit: 6,
      hideMarkerOnCollapse: true,
      tipAutoSubmit: true,
      firstTipSubmit: true,
      textPlaceholder: resellersPlaceholder,
    });

    resellersMap.addControl(
      controlSearch.on('search:locationfound', function (data) {
        if (data !== undefined) {
          let latlngBase = L.latLng(data.latlng);
          calcResellerDistance(resellersMap, latlngBase);
        }
      })
    );

    // Get user location
    $.get(
      'https://ipinfo.io',
      function (response) {
        if (response && response.loc) {
          let latLng = response.loc.split(',');
          calcResellerDistance(resellersMap, {
            lat: latLng[0],
            lng: latLng[1],
          });
          $(document).find('input.search-input').focus();
        }
      },
      'jsonp'
    ).fail(function () {
      alert('error');
    });
  }

  $('.single_variation_wrap').on('show_variation', function (event, variation) {
    if (event && variation.image_id !== undefined) {
      let slickIndex = $('.product_slider')
        .find('[data-id=' + variation.image_id + ']')
        .data('slick-index');
      $('.product_slider').slick('slickGoTo', slickIndex);
    }

    if (!$('.woocommerce-variation-price').html()) {
      $('.woocommerce-variation-price').html(
        $('.product-type-variable p.price').html()
      );
    }
  });

  // Toggle coupon button
  $(document).on('click', '.js-discount-toggle', function (e) {
    e.preventDefault();
    e.stopPropagation();

    $(this).closest('.woocommerce-page').addClass('coupon-is-visible');
  });

  initSlick();
  resizeVideo();
  innerSizer();
  innerSizer('.flex_item');
  slidersInit();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  initSlick();
  resizeVideo();
  innerSizer();
  innerSizer('.flex_item');
  slidersInit();
});

/**
 * Scripts which runs on scrolling
 */
let lastScrollTop = 0;
$(window).on('scroll', function () {
  // jQuery code goes here
  let st = $(this).scrollTop();
  $('html')
    .toggleClass('scrolling-top', st < lastScrollTop)
    .toggleClass('scrolling-bottom', st > lastScrollTop);
  if (st === 0) $('html').removeClass('scrolling-top scrolling-bottom');
  lastScrollTop = st;
});

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function openMapInit() {
  $('.js-opensource-map').each(function () {
    let openMap = $(this);

    let markersData = openMap.attr('data-markers')
      ? JSON.parse(openMap.attr('data-markers'))
      : null;

    let map = L.map(openMap.attr('id'), { scrollWheelZoom: false });
    new L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    let openMapIcon = L.divIcon({
      iconSize: [48, 65],
      popupAnchor: [0, -20],
      className: 'flex_stories__map_icon',
    });

    L.tileLayer
      .provider('Jawg.Streets', {
        variant: 'jawg-light',
        accessToken:
          'KjsMaU3CpceAPzj7QCAUvdEApKqBNY6jv0FgSjsfzFXofAV9qBq104cjFkXw3yu0',
      })
      .addTo(map);

    if (markersData) {
      let mapBounds = [];
      $.each(markersData, function (i, el) {
        if (el.lat && el.lng) {
          L.marker([el.lat, el.lng], {
            icon: openMapIcon,
          })
            .addTo(map)
            .bindPopup(el.address);
          mapBounds[i] = [el.lat, el.lng];
        }
      });

      map.fitBounds(mapBounds).setZoom(9).zoomControl.setPosition('bottomleft');
    }
  });
}

function searchResellers($form) {
  if ($form.find('input').length) {
    let $resellersContent = $('.js-resellers-content');
    $resellersContent.addClass('searching');

    // AJAX Search
    $.post(ajax_object.ajax_url, {
      action: 'find_reseller',
      value: $form.find('input').val(),
    }).done(function (response) {
      $resellersContent.html(response.html).removeClass('searching');
      $('.js-no-result').toggleClass('d-none', !!response.html);
    });
  }
}

function calcResellerDistance(map, latlngBase) {
  if (latlngBase && $('.js-reseller').length) {
    $('.js-reseller').each(function () {
      let latReseller = $(this).data('lat');
      let lngReseller = $(this).data('lng');
      if (latReseller && lngReseller) {
        let latlngDestination = L.latLng(latReseller, lngReseller);
        let distance = map.distance(latlngBase, latlngDestination) / 1000;
        $(this)
          .find('.js-reseller-distance')
          .text(distance.toFixed(2) + ' km');
        $(this)
          .parent()
          .css('order', parseInt(distance * 100));
      }
    });
  }
}

function initSlick() {
  $('.product_slider:not(.slick-initialized)').slick({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 0,
    slide: '.product_slider__item',
  });

  $('.js-flex-columns:not(.slick-initialized)').slick({
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 0,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 992,
        settings: 'unslick',
      },
    ],
  });
}

function innerSizer(blockClass = '.flex_hero') {
  $(blockClass).each(function (i, el) {
    let $inner = $(el).find(blockClass + '__inner');
    $(el).css('min-height', '');
    $inner.css('height', '');

    if ($inner.length && $inner.prop('scrollHeight') > $(el).outerHeight()) {
      $(el).css(
        'min-height',
        $inner.prop('scrollHeight') + parseInt($inner.css('padding-bottom'))
      );
      $inner.css('height', $inner.prop('scrollHeight'));
    } else {
      $inner.css('height', '100%');
    }
  });
}

function slidersInit() {
  $(document)
    .find('.js-usps:not(.slick-initialized)')
    .slick({
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 3000,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 992,
          settings: 'unslick',
        },
      ],
    });

  let $heroSlider = $(document).find('.js-hero-slider:not(.slick-initialized)');
  if ($heroSlider.length) {
    let heroAnimation = $heroSlider.data('animation') ? true : false;
    let heroSpeed = $heroSlider.data('speed');
    let heroAutoplay = $heroSlider.data('speed') ? true : false;
    $heroSlider.slick({
      dots: true,
      arrows: false,
      infinite: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      autoplay: heroAutoplay,
      autoplaySpeed: heroSpeed,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: heroAnimation,
    });
  }
}
